import axios from '@/util/request'
//综合测评

//GET /mobile/content-info/getContentInfo
//内容-获取单个详情
export function getContentInfo(params) {
    return axios({
        url: '/mobile/content-info/getContentInfo',
        method: 'get',
        params
    })
}

// /com/had-login/assess-problem/getProblemPage
// 题库 查询列表 1 兴趣 2性格 3能力 assess
export function getProblemPage(data) {
    return axios({
        url: '/com/had-login/assess-problem/getProblemPage',
        method: 'post',
        data
    })
}

// /com/had-login/assess-answer/saveAnswer
// 答题
export function saveAnswer(data) {
    return axios({
        url: '/com/had-login/assess-answer/saveAnswer',
        method: 'post',
        data
    })
}

// /com/had-login/assess-answer/getAnswerResult
// 测评结果详情
export function getAnswerResult(params) {
    return axios({
        url: '/com/had-login/assess-answer/getAnswerResult',
        method: 'get',
        params
    })
}