<template>
  <div>
    <!-- <div style="display: flex; justify-content: space-between">
        <span class="all">总题数：{{ tablePageData.pageTotal }}</span>
      </div> -->

    <template v-if="formdata">
      <div class="top">
        <el-card shadow="hover" class="right">
          <div style="margin-bottom: 3px">
            <span
              v-for="(item1, index1) in formdata"
              :key="index1"
              @click="changeindex(index1)"
              class="qList"
              :class="{
                'is-active': item1.topic_answer,

                'is-active-green': index1 == current,
              }"
            >
              {{ index1 + 1 }}
            </span>
          </div>
        </el-card>
      </div>

      <div class="">
        <div class="left">
          <template v-for="(item, index) in formdata">
            <div v-if="index == current" class="questions" :key="index">
              <div class="question2">
                <div style="margin: 20px 0">
                  <span>{{ index + 1 }}.</span>
                  <span>{{ item.title }}</span>
                  <span>[单选]</span>
                </div>

                <el-card>
                  <div>
                    <el-radio-group
                      v-model="item.topic_answer"
                      @change="nextchange"
                    >
                      <el-radio
                        style="display: block; margin: 10px 0px"
                        label="a"
                        v-if="item.optionsA"
                      >
                        {{ item.optionsA }}
                      </el-radio>
                      <el-radio
                        style="display: block; margin: 10px 0px"
                        label="b"
                        v-if="item.optionsB"
                      >
                        {{ item.optionsB }}
                      </el-radio>
                      <el-radio
                        style="display: block; margin: 10px 0px"
                        label="c"
                        v-if="item.optionsC"
                      >
                        {{ item.optionsC }}
                      </el-radio>
                      <el-radio
                        style="display: block; margin: 10px 0px"
                        label="d"
                        v-if="item.optionsD"
                      >
                        {{ item.optionsD }}
                      </el-radio>
                      <el-radio
                        style="display: block; margin: 10px 0px"
                        label="e"
                        v-if="item.optionsE"
                      >
                        {{ item.optionsE }}
                      </el-radio>
                    </el-radio-group>
                  </div>
                </el-card>
              </div>
            </div>
          </template>
        </div>
      </div>
      <div class="footer">
        <el-button @click="prechange" type="primary" size="small"
          >上一题</el-button
        >
        <el-button @click="nextchange" type="primary" size="small"
          >下一题</el-button
        >
        <el-button @click="handleSubmit" type="primary" size="small"
          >提交</el-button
        >
      </div>
    </template>
    <template v-else>
      <div>暂无数据</div>
    </template>
  </div>
</template>
<script>
import {
  getProblemPage,
  saveAnswer,
  getAnswerResult,
} from "@/api/comprehensive";
export default {
  name: "answer",
  data() {
    return {
      data: {},
      modal11: true,
      state: false,
      Index: [],
      timer: "",
      id: "",//测评id
      current: 0,
      currentAns: "",
      formdata: [], //题目
      tablePageData: {
        page: 1,
        size: 500,
        assess: null,
      },
    };
  },
  watch: {},
  created() {
    this.tablePageData.assess = this.$route.query.assess - 0;
    this.id = this.$route.query.id 
    this.reqTabledata();
  },
  mounted() {},
  methods: {
    // 获取题目
    reqTabledata() {
      getProblemPage(this.tablePageData).then((res) => {
        if (res.code == 1) {
          let data = res.data || {};
          this.formdata = data.records || [];
        }
      });
    },
    // 改变选项
    changeindex(index) {
      this.current = index;
    },
    // 上一题
    prechange() {
      if (this.current > 0) {
        this.current--;
      }
    },
    // 下一题
    nextchange() {
      setTimeout(() => {
        if (this.current < this.formdata.length - 1) {
          this.current++;
        }
      }, 200);
    },
    // 交卷
    handleSubmit() {
      let assessAnswers = [];
      console.log(this.formdata, "this.formdata");
      this.formdata.forEach((item, index) => {
        if (item.topic_answer) {
          assessAnswers.push({ answer: item.topic_answer, problemId: item.id });
        }
      });
      console.log(assessAnswers, "assessAnswers");
      if (assessAnswers.length == this.formdata.length) {
        let form = {};
        form.isInterest = 0; // 兴趣
        form.isCharacter = 0; // 性格
        form.isAbility = 0; //能力
        if (this.tablePageData.assess == 1) {
          form.isInterest = 1; // 兴趣
        }
        if (this.tablePageData.assess == 2) {
          form.isCharacter = 1; // 性格
        }
        if (this.tablePageData.assess == 3) {
          form.isAbility = 1; //能力
        }
        form.assessAnswers = assessAnswers;
        if(this.id){
          form.id = this.id
        }
        saveAnswer(form).then((res) => {
          if (res.code == 1) {
            let data = res.data || {};
            // 调测评详情判断是否答完
            getAnswerResult({ id: data.id }).then((result) => {
              if (result.code == 1) {
                let detail = result.data || {}
                if (detail.isAbility && detail.isCharacter && detail.isInterest) {
                  // 全部答完，跳测评结果
                  this.$router.push({path: "/volunterservce/Comprehensive/details",query: {id : detail.id}})
                } else {
                  //未答完跳转到类型选择
                  this.$router.push({
                    path: "/volunterservce/Comprehensive/evaluation",
                    query: {id : detail.id},
                  });
                }
              }
            });
          }
        });
      } else {
        this.$message.error("您还有题目未答，请先完成答题");
      }
    },
    // 选择题答案
    slectAns(item) {
      this.currentAns = item.topic_message;
      console.log(item);
    },
    // 跳页
    handleChange(page) {
      this.tablePageData.page = page;
      this.reqTabledata();
    },
  },
};
</script>
<style lang="less" scoped>
.all {
  background-color: rgb(2, 167, 242);
  margin-bottom: 30px;
  color: white;
  font-size: 22px;
  display: inline-block;
}
.cont {
  display: flex;
  .left {
    width: 65%;
    border: 1px solid gray;
  }

  .questions {
    padding: 30px 0 30px 18px;
    // margin-bottom: 30px;
    background-color: rgb(242, 242, 242);
    .question2 {
      margin: 10px 0px 20px 0px;
      position: relative;
      div {
        margin: 10px 0;
      }
    }

    .point {
      background-color: red;
      // border: 1px solid;
      color: white;
      border-radius: 50%;
      width: 18px;
      height: 18px;
      text-align: center;
      display: inline-block;
    }
    .point1 {
      font-weight: bold;
    }
    .but {
      // display: block;
      position: absolute;
      // bottom: 30px;
      top: 10px;
      right: 30px;
    }
  }
}
.qList {
  background-color: #ffffff;
  margin-right: 1px;
  box-sizing: border-box;
  border: 1px solid #e5e5e5;
  cursor: pointer;
  color:#666;
  width: 30px;
  display: inline-block;
  line-height: 30px;
  text-align: center;
  border-radius: 3px;
  margin: 5px;
}
.footer {
  margin-top: 30px;
  float: right;
}
.is-active {
  background-color: #e6e6e6;
  color: #b4b4b4;
  border: 0px;
}
.is-active-green {
  color: #fff;
  background-color: #1ab394;
  border: 0px;
}
/deep/.el-radio__input.is-checked + .el-radio__label {
  color: #1ab394;
}
/deep/ .el-radio__input.is-checked .el-radio__inner {
  border-color: #1ab394;
  background: #1ab394;
}
/deep/.el-button--primary {
  color: #fff;
  background-color: #1ab394 !important;
  border-color: #1ab394 !important;
}
</style>